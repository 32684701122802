<template>
  <div>
    <Header ref="header"/>

    <div class="content article-content">
      <div style="height: 20px"></div>
      <div class="w w1200 clearfix">
        <section class="Title">欢乐砸金蛋</section>

        <section id="main">
          <div class="egg">
            <div class="Opportunity">你还有<strong><font id="chance">{{chance}}</font></strong>次机会</div>
            <div class="eggList">
              <p style="left: 234px;" class="hammer" id="hammer">锤子</p>
              <div class="resultTip" id="resultTip"><b>{{result}}</b></div>
              <div class="eggbtn" @click.s.stop="eggClick"><span></span></div>
            </div>
          </div>
          <div class="bg"></div>
        </section>

        <section class="InfoBox">
          <div class="winners_user">
            <div class="ico"></div>
            <div id="winners_user">
              <div id="winners_info">
                <ul id="init_data">
                  <li v-html='chanceInfo'></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="relevant-list">
            <div class="award_list_ico">
              <router-link :to="{path:'/marketing/lottery/log'}" target="_blank" tag="a">
                <span></span>
                <strong>抽奖记录</strong>
              </router-link>
            </div>
<!--            <div class="my_list_ico">-->
<!--              <a href="javascript:;" onclick="showmyprize();">-->
<!--                <span></span>-->
<!--                <strong>抽奖记录</strong>-->
<!--              </a>-->
<!--            </div>-->
            <div class="rule_ico">
              <router-link :to="{path:'/marketing/SmashGoldenEggs/rule'}" target="_blank" tag="a">
                <span></span>
                <strong>规则说明</strong>
              </router-link>
            </div>
          </div>

          <div id="rule_box" style="display:none;" class="prompt_box">
            <div class="column"><span class="close" onclick="easyDialog.close();">X</span><h3>规则说明</h3></div>
            <div class="prompt_info" style="height:200px; overflow-y:scroll;" id="huodong_desc_div">

            </div>
          </div>
        </section>

      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import {getInfo} from '@/api/mall/marketing/lottery/luckDrawCustomer'
import {getEggs} from '@/api/mall/marketing/lottery/luckDrawGoods'
import $ from 'jquery'
export default {
  name: "index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      result: '',
      chance: 0,
      chanceInfo: '',
      OrderAmount: 0,
      UsedAmount: 0,
      goods:[],
    }
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    this.getInfo()
  },
  methods:{
    eggClick() {
      // eslint-disable-next-line no-unused-vars
      let _t = this
      var _this = $(".eggbtn");
      if(_this.hasClass("curr")){
        if(confirm("蛋都碎了，别砸了！刷新再来.")){
           //window.location=window.location;
        }
        return;
      }

      $(".hammer").css({"top":_this.position().top-55,"left":_this.position().left+185});
      $(".hammer").animate({"top":_this.position().top -25, "left":_this.position().left+125},50,function(){
            _this.addClass("curr"); //蛋碎效果
            $(".hammer").hide();
            _t.result = '';
            getEggs().then(res => {
              let data = res.data
              if(data.Msg==''){
                if(data.Prize!='' && data.PrizeName!=''){
                  _t.result = '恭喜你，抽中 ' + data.PrizeName;
                }else{
                  _t.result = '很遗憾！您未中奖~~~';
                }
              }else{
                _t.result = data.Msg;
              }

              _t.chance = _t.chance - 1 > 0 ? _t.chance - 1 : 0
              _t.chanceInfo = '你有'+_t.chance+'次砸金蛋机会哦~~~';

              $('.resultTip').css({display:'block',top:'50px',left:_this.position().left+80,opacity:0}).animate({top: '10px',opacity:1},300,function(){

                _this.removeClass("curr"); //蛋碎效果
                $(".hammer").css({"top":_this.position().top+10,"left":_this.position().left+234});
                $(".hammer").show();
              });
            });
          }
      );
    },
    getInfo(){
      getInfo().then(res => {

        if(res.data){
          this.OrderAmount = res.data.OrderAmount
          this.UsedAmount = res.data.UsedAmount

          if((this.OrderAmount - this.UsedAmount) < 20000){
            this.chance = 0;
            this.chanceInfo = '您不符合砸金蛋要求，再采购<span>'+Math.ceil((20000-(this.OrderAmount - this.UsedAmount)))+'</span>元，可进行一次砸金蛋哦~~~';
          }else{
            this.chance = Math.trunc((this.OrderAmount - this.UsedAmount) / 20000);
            this.chanceInfo = '你有'+this.chance+'次砸金蛋机会哦~~~';
          }
        }else{
          this.chance = 0;
          this.chanceInfo = '您不符合砸金蛋要求，再采购<span>20000</span>元，可进行一次砸金蛋哦~~~';
        }

      })
    },
    getGoods(){

    }
  }
}
</script>

<style scoped>
.w{
  color:#333333; background:#f4f0ca;
}

.Title{width:100%; height:48px; font:18px/48px "Microsoft YaHei"; color:#fff; background:#d03237; text-align:center; position:relative;}

#main{width:100%; height:302px; background:#e8383d; position:relative;}
#main .bg{width:100%; height:58px; background:#f4f0ca; position:absolute; z-index:1; bottom:0px;}

.egg{width:310px; height:auto; margin:0px auto; padding-top:50px; z-index:2; position:relative; background:url(../../../../assets/marketing/SmashGoldenEggs/MainBg.jpg) top center no-repeat; background-size: 340px 302px;}
.eggList{padding-top:0px; position:relative; width:310px; margin:0px auto;}
.eggbtn{background:url(../../../../assets/marketing/SmashGoldenEggs/egg_1.png) no-repeat bottom; width:158px; height:187px; margin:0px auto; cursor:pointer; position:relative; background-size: 158px 161px;}
.eggbtn span{position:absolute; width:30px; height:60px; left:68px; top:64px; color:#ff0; font-size:42px; font-weight:bold}
.eggbtn.curr{background:url(../../../../assets/marketing/SmashGoldenEggs/egg_2.png) no-repeat bottom;cursor:default;z-index:300; background-size: 158px 161px;}
.eggbtn.curr sup{position:absolute;background:url(../../../../assets/marketing/SmashGoldenEggs/img-4.png) no-repeat;width:232px; height:181px;top:-36px;left:-34px;z-index:800;}
.hammer{background:url(../../../../assets/marketing/SmashGoldenEggs/img-6.png) no-repeat; width:74px; height:87px; position:absolute; text-indent:-9999px; z-index:150; left:168px; top:10px;}
.resultTip{position:absolute; background:#ffc ;width:148px;padding:6px;z-index:500;top:200px; left:10px; color:#f60; text-align:center;overflow:hidden;display:none;z-index:500;}
.resultTip b{font-size:14px;line-height:24px;}

.InfoBox{padding:0px 10px;}

/*抽奖机会*/
.Opportunity{width:120px; height:26px; margin:30px auto 0px; background:#ba2d31; text-align:center; font:12px/24px "Microsoft YaHei"; color:#fff; border-radius:30px;}

/*中奖人滚动字幕*/
.winners_user{width:auto; height:24px; background:#f2e7b9; margin:0px auto; position:relative; margin-top:10px;}
.winners_user .ico{width:29px; height:24px; position:absolute; left:0px; top:0px; z-index:999; background:url(../../../../assets/marketing/SmashGoldenEggs/winners_user.png) center top no-repeat; background-size: 29px 24px;}
.winners_user #winners_user{width:auto; height:24px; overflow:hidden; position:relative;}
.winners_user #winners_info{position:absolute; left:0px; top:0px;}
.winners_user #winners_info ul{width:100000%; height:24px;}
.winners_user #winners_info li{padding-left:50px; line-height:24px; white-space:nowrap;}
.winners_user #winners_info li span{color:#e8471d; font-weight:bold;}

/*三个按钮*/
.relevant-list{width:100%; height:80px; margin:0px auto; padding:20px 0px; overflow:hidden; position:relative;}
.relevant-list div{width:28%; height:80px; text-align:center; font-size:14px;}
.relevant-list div a{display:block; height:80px;}
.relevant-list div a span{margin-top:15px;}
.relevant-list div a strong{font-weight:normal; display:block; line-height:1;}
.relevant-list .award_list_ico{background:#fff; position:absolute; left:0px; top:20px;}
.relevant-list .award_list_ico span{width:32px; height:32px; display:inline-table; background:url(../../../../assets/marketing/SmashGoldenEggs/relevant-list.png) -37px top no-repeat; background-size:319px 32px;}
.relevant-list .my_list_ico{margin:0px auto; background:#fff;}
.relevant-list .my_list_ico span{width:32px; height:32px; display:inline-table; background:url(../../../../assets/marketing/SmashGoldenEggs/relevant-list.png) -144px top no-repeat; background-size:319px 32px;}
.relevant-list .rule_ico{background:#fff; position:absolute; right:0px; top:20px;}
.relevant-list .rule_ico span{width:32px; height:32px; display:inline-table; background:url(../../../../assets/marketing/SmashGoldenEggs/relevant-list.png) -251px top no-repeat; background-size:319px 32px;}

/*弹框*/
.prompt_box{width:280px; background:#fff; padding:0px 10px 10px;}
.prompt_box .column{height:40px; border-bottom:2px solid #fba10f; margin-bottom:10px; overflow:hidden;}
.prompt_box .column h3{font-size:18px; color:#faa00c; line-height:40px; padding-left:10px; font-weight:normal; display:block;}
.prompt_box .column .close{float:right; height:40px; width:40px; display:block; cursor:pointer; background:url(../../../../assets/marketing/SmashGoldenEggs/close.png) top center no-repeat; text-indent:-9999px;}
.prompt_box .prompt_info{padding:10px 0px; font-size:14px;}
.prompt_box .prompt_info #Noaward{color:#9fa0a0; text-align:center; display:block;}
.prompt_box .prompt_info #Noaward strong{display:block; font-size:16px; color: #444444;}
.prompt_box .prompt_info #theAward{color:#9fa0a0; text-align:center; display:block;}
.prompt_box .prompt_info #theAward strong{display:block; font-size:16px; color: #444444;}
.prompt_box .prompt_info #theAward strong span{color:#fba10f;}

/*奖品列表*/
.award_list{width:100%; height:auto; margin:0px auto;}
.award_list ul{padding:10px;}
.award_list ul li{position:relative; margin-bottom:20px;}
.award_list ul li .ranking{width:32px; height:32px; text-align:center; line-height:32px; position:absolute; left:0px; top:0px; background:#faa113; color:#fff; font-size:18px;}
.award_list ul li.award_1 .ranking{background:#e8471d;}
.award_list ul li img{width:100%; height:150px; border-radius:3px;}
.award_list ul li .award_name{font-size:18px; text-align:center; padding-top:3px; display:block;}

/*我的奖品*/
.my_list{width:100%; height:auto; margin:0px auto;}
.my_list ul{padding:0px 10px;}
.my_list ul li{padding:10px 0px; border-bottom:1px solid #ddddde; overflow:hidden;}
.my_list ul li .award_ico{width:64px; height:64px; float:left; overflow:hidden; border-radius:5px;}
.my_list ul li .award_ico img{width:80px; border-radius:5px;}
.my_list ul li .award_info{float:left; padding-left:10px; font-size:11px; overflow:hidden;}
.my_list ul li .award_info strong{font-size:18px; color:#e8471d; display:block;}
.my_list ul li .award_btn{float:right; display:inline-table; padding:5px 10px; background:#e8471d; font-size:15px; text-align:center; border-radius:5px; margin-top:15px;}
.my_list ul li .award_btn a{color:#fff;}
</style>
