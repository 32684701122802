import request from "@/utils/request";

export function getEggs() {
  return request({
    url: '/app/mall/meeting/luckDraw/goods/eggs',
    method: 'GET',
    needToken: true
  })
}

export function getGrid() {
  return request({
    url: '/app/mall/meeting/luckDraw/goods/grid',
    method: 'GET',
    needToken: true
  })
}

export function getListByType(type) {
  return request({
    url: `/app/mall/meeting/luckDraw/goods/list/${type}`,
    method: 'GET',
    needToken: true
  })
}
